<!-- =========================================================================================
    File Name: removeLevel.vue
    Description: REMOVE LEVEL
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard 
    Author:  
    Author URL: 
========================================================================================== -->


<template>
  <div>
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full mb-2">
        <vx-card title="REMOVE ON LEVEL">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <!-- <div class="vx-col w-full md:w-1/2 mb-base">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>Project Name</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-select placeholder="User" class="w-full select-large">
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item,index) in cityOptions"
                        class="w-full"
                      />
                    </vs-select>
                  </div>
                </div>
              </div>-->
              <div class="vx-col w-full md:w-1/2 mb-base">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>Level</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-select placeholder="User" class="w-full select-large">
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item,index) in cityOptions"
                        class="w-full"
                      />
                    </vs-select>
                  </div>
                </div>
              </div>

              <div class="vx-col w-full md:w-1/2 mb-base">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>User Name</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-select placeholder="Expense Category" class="w-full select-large">
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item,index) in cityOptions"
                        class="w-full"
                      />
                    </vs-select>
                  </div>
                </div>
              </div>

              <div class="vx-col w-full md:w-1/2 mb-base">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/4 w-full">
                    <span>Department Name</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <vs-select placeholder="Expense Category" class="w-full select-large">
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="item.text"
                        v-for="(item,index) in cityOptions"
                        class="w-full"
                      />
                    </vs-select>
                  </div>
                </div>
              </div>

              <div class="vx-row w-100">
                <div class="vx-col md:w-1/3 w-full text-right">
                  <vs-button class="mr-3 mb-2">Submit</vs-button>
                  <vs-button
                    color="warning"
                    type="border"
                    class="mb-2"
                    @click="input1 = input2 = input3 = input4 = input4 = ''; check1 = false;"
                  >Reset</vs-button>
                </div>
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";

const VxTour = () => import("@/components/VxTour.vue");

import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
  },
  data() {
    return {
      User: "Select User",

      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      columnDefs: [
        {
          headerName: "Sr. NO",
          field: "firstname",
          width: 275,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: "User Profile",
          field: "lastname",
          filter: true,
          width: 275,
        },
        {
          headerName: "User Name",
          field: "email",
          filter: true,
          width: 375,
          //   pinned: "left"
        },
        {
          headerName: "Department",
          field: "company",
          filter: true,
          width: 200,
        },
        {
          headerName: "Level",
          field: "city",
          filter: true,
          width: 200,
        },
        {
          headerName: "Edit",
          field: "",
          filter: false,
          width: 80,
          cellStyle: { "text-align": "center" },
          cellRenderer: function(params) {
            return '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>';
          },
        },
        {
          headerName: "Status",
          field: "lastname",
          filter: false,
          width: 150,
          cellStyle: { "text-align": "center" },
          cellRenderer: function(params) {
            return '<label class="switch"> <input type="checkbox" checked> <span class="slider round"></span> </label>';
          },
        },
        {
          headerName:
            this.archive == "archive" ? "Unarchive" : "Archive",
          field: "",
          filter: false,
          width: 150,
          cellStyle: { "text-align": "center" },
          cellRenderer: function(params) {
            return '<span class="text-center table-edit-icon"><i class="text-center material-icons">archive</i></span>';
          },
        },
      ],
      contacts: contacts,
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else
        this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi)
          return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
