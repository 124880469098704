<template>
  <div id="dashboard-analytics">
    <div class="vx-col w-full mb-4">
      <vx-card class="form-title" title="Edit Project">
        <div
          class="col-12 mb-3 text-right"
          v-if="checkShowHideButton && UserRoleCheck == 'Admin'"
        >
          <vs-button @click="checkAdvancedOption"
            >{{ projectDetail.isAdvanced == 0 ? "Show" : "Hide" }} Advanced
            Option</vs-button
          >
        </div>
        <form>
          <div class="row">
            <div class="col-6 mb-3">
              <h6>Project Name*</h6>

              <vs-input
                v-validate="'required'"
                name="project name"
                v-model="projectDetail.project_name"
                class="w-full"
                rules="alpha"
                disabled
              />
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('project name')"
                  >{{ errors.first("project name") }}</span
                >
              </p>
            </div>

            <div class="col-6 mb-3">
              <h6>Company Name*</h6>
              <multiselect
                v-validate="'required'"
                v-model="company_value"
                track-by="company_id"
                label="company_name"
                @input="
                  ({ company_id }) =>
                    (this.projectDetail.company_id = company_id)
                "
                :options="companies"
                name="Company Name"
                :searchable="false"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
                @select="onSelectCompany"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.company_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Company Name')"
                  >{{ errors.first("Company Name") }}</span
                >
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 mb-2">
              <h6>Type of Project</h6>
              <vs-input
                type="text"
                name="Type of Project"
                v-model="projectDetail.project_type"
                :disabled="contractForm"
                class="w-full"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Type of Project')"
                  >{{ errors.first("Type of Project") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>Type of Show</h6>
              <vs-input
                type="text"
                name="Type of Show"
                :disabled="contractForm"
                v-model="projectDetail.show_type"
                class="w-full"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Type of Show')"
                  >{{ errors.first("Type of Show") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>Directors</h6>
              <vs-input
                type="text"
                name="Directors"
                :disabled="contractForm"
                v-model="projectDetail.directors"
                class="w-full"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Directors')"
                  >{{ errors.first("Directors") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>Writers</h6>
              <vs-input
                type="text"
                name="Writers"
                v-model="projectDetail.writers"
                class="w-full"
                :disabled="contractForm"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Writers')"
                  >{{ errors.first("Writers") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>Lead Cast</h6>
              <vs-input
                type="text"
                name="Lead Cast"
                v-model="projectDetail.lead_cast"
                :disabled="contractForm"
                class="w-full"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Lead Cast')"
                  >{{ errors.first("Lead Cast") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>Internal CD</h6>
              <vs-input
                type="text"
                name="Internal CD"
                :disabled="contractForm"
                v-model="projectDetail.internal_cd"
                class="w-full"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Internal CD')"
                  >{{ errors.first("Internal CD") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>No. of Episodes</h6>
              <vs-input
                type="number"
                name="No. of episodes"
                :disabled="contractForm"
                v-model="projectDetail.episodes"
                class="w-full"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('No. of episodes')"
                  >{{ errors.first("No. of episodes") }}</span
                >
              </p>
            </div>
            <div class="col-6 mb-2">
              <h6>Shoot Days</h6>
              <vs-input
                type="number"
                name="Shoot Days"
                :disabled="contractForm"
                v-model="projectDetail.shoot_days"
                class="w-full"
              />
              <p class="error-msg text-sm">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Shoot Days')"
                  >{{ errors.first("Shoot Days") }}</span
                >
              </p>
            </div>
          </div>
          <!-- <div v-if="projectDetail.isAdvanced==1">
            <div class="row">
              <div class="col-6 mb-3">
                <h6>Production/ Accountant</h6>
                <multiselect
                  v-model="form.moderator_array"
                  track-by="user_id"
                  label="user_name"
                  :options="AuditorList"
                  multiple
                  placeholder="Production/ Accountant"
                  :searchable="true"
                  :disabled="projectForm"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{
                    option.user_name
                    }}
                  </template>
                </multiselect>
                <p class="error-msg">
                  <span class="text-danger text-sm" v-show="errors.has('User')">
                    {{
                    errors.first("User")
                    }}
                  </span>
                </p>
              </div>

              <div class="col-6 mb-3">
                <h6>Project Manager*</h6>
                <multiselect
                  v-model="form.adminManager_array"
                  track-by="user_id"
                  label="user_name"
                  multiple
                  :disabled="projectForm"
                  :options="adminManegerUsers"
                  placeholder="Select Project Manager"
                  name="Admin Manager"
                  :searchable="true"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{
                    option.user_name
                    }}
                  </template>
                </multiselect>
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Admin Manager')"
                  >{{ errors.first("Admin Manager") }}</span>
                </p>
              </div>
            </div>
          </div>-->

          <div class="row">
            <div class="col-6 mb-3">
              <h6>Description</h6>
              <vs-textarea
                v-model="projectDetail.project_details"
                class="mb-0"
                rows="4"
              />
            </div>

            <div class="col-6 mb-3">
              <div class="row">
                <div class="col-md-6 col-xl-4">
                  <h6>Upload Project Icon</h6>

                  <div class="text-center">
                    <label
                      for="updateProfilePicture"
                      class="position-relative mb-0"
                    >
                      <img
                        v-if="
                          projectDetail.project_icon &&
                          projectDetail.project_icon != '-'
                        "
                        :src="
                          projectDetail.project_icon + '?token=' + tempToken
                        "
                        class="img-fluid"
                      />
                      <!-- profile-img -->
                      <img
                        v-else
                        src="https://img.gokozo.com/c5d9223c6127d9bc_org.png"
                        class="img-fluid"
                      />
                      <div class="comp-logo-edit">
                        update
                        <!-- <i class="text-center material-icons align-middle">arrow_upward</i> update -->
                      </div>
                    </label>
                    <input
                      :disabled="checkViewEditPermission"
                      type="file"
                      @change="uploadFile($event)"
                      class="d-none"
                      id="updateProfilePicture"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-xl-4">
                  <h6>Budget Active</h6>
                  <b-form-checkbox
                    :disabled="checkViewEditPermission"
                    value="1"
                    unchecked-value="0"
                    v-model="projectDetail.project_budgetactive"
                    name="check-button"
                    switch
                  ></b-form-checkbox>
                </div>
                <div
                  class="col-md-6 col-xl-4"
                  v-if="checkUserType == 1 || checkUserType == 2"
                >
                  <h6>Contract Active</h6>
                  <b-form-checkbox
                    value="1"
                    unchecked-value="0"
                    v-model="projectDetail.contract_budgetactive"
                    name="check-button"
                    switch
                  ></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <!-- ADD LEVELS CARD END -->
          <!-- SUBMIT BUTTON -->
          <!-- :disabled="count > 2 && !currentLevelUsers" -->
          <div class="vx-row">
            <div class="vx-col mx-auto editProject">
              <!-- :disabled="submitStatus
              || !isUserSelected"-->
              <vs-button
                type="filled"
                class="mr-3 text-white"
                @click.prevent="submitForm()"
                :disabled="checkStatusButton || checkViewEditPermission"
                >Update Project
              </vs-button>
              <!--
              <vs-button
                color="warning"
                type="border"
                class="text-warning"
                @click.prevent="clearForm()"
              >Reset</vs-button>-->
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-6" v-if="mod_array_new.length > 0">
              <h6 class="text-primary">Moderator:</h6>
              <div class="border bg-light p-3 rounded listOfUsers">
                <span v-for="(item, index) in mod_array_new" :key="index">{{
                  item.user_name
                }}</span>
              </div>
            </div>
            <div class="col-6" v-if="admin_array_new.length > 0">
              <h6 class="text-primary">Admin Manager:</h6>
              <div class="border bg-light p-3 rounded listOfUsers">
                <span v-for="(item, index) in admin_array_new" :key="index">{{
                  item.user_name
                }}</span>
              </div>
            </div>
          </div>
        </form>
      </vx-card>
    </div>

    <vx-card v-if="projectDetail.isAdvanced == 1">
      <!-- ADD USER -->
      <vs-popup
        class="holamundo editModal assigneePopup"
        title="Add User"
        :id="'popmodaluser'"
        :active.sync="popupActive"
      >
        <div class="vx-col mb-base">
          <div class="vx-row mx-auto">
            <div class="vx-col md:w-1/4">User</div>
            <div class="vx-col md:w-3/4">
              <multiselect
                v-model="user_value"
                track-by="user_id"
                label="user_name"
                placeholder="Select User"
                @input="({ user_id }) => (this.user_id = user_id)"
                :options="users"
                name="User Name"
                :searchable="true"
                open-direction="bottom"
                :allow-empty="false"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.user_name }}
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col mx-auto">
            <vs-button type="filled" @click.prevent="onAddUser()" class="mr-3"
              >Add User</vs-button
            >
          </div>
        </div>
      </vs-popup>

      <!-- REMOVE USER -->
      <vs-popup
        class="holamundo p-2"
        title="Remove User"
        :active.sync="popupActive1"
      >
        <div class="text-center">
          <h5>
            You want to remove {{ selectedUser.user_name }} from Level
            {{ selectedLevel }}
          </h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mx-auto mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeRemove($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>

      <!-- ADD NEW LEVEL -->
      <vs-popup
        class="holamundo editModal addlevel-popupc assigneePopup"
        :title="'Add Level ' + newlevels.levelName"
        :id="'popmodallevel'"
        :active.sync="popupActive2"
      >
        <div class="vx-col mb-base">
          <div class="vx-row">
            <div class="vx-col md:w-1/4">Select User</div>
            <div class="vx-col md:w-3/4">
              <multiselect
                v-model="userDataValue"
                track-by="name"
                label="name"
                placeholder="Select one"
                :options="userDataAvailable"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
                @select="optionSelected"
                @remove="optionRemoved"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>{{ option.name }}</strong>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="vx-row mt-4">
            <div class="vx-col md:w-1/4">Select Level</div>
            <div class="vx-col md:w-3/4">
              <multiselect
                v-model="level_add"
                track-by="levelName"
                label="levelShowName"
                placeholder="Select Level"
                :options="showLeveldrop"
                name="User Name"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.levelShowName }}
                </template>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col mx-auto">
            <vs-button type="filled" @click.prevent="AddLevel()" class
              >Add Level</vs-button
            >
          </div>
        </div>
      </vs-popup>

      <div class="row">
        <div class="col-6 mb-1">
          <h5 class="font-bold">User Level :</h5>
        </div>
      </div>

      <div class="vx-row mx-0">
        <!-- <div class="row px-5 py-5 mb-5"></div> -->

        <div class="px-5 py-5">
          <ul class="activity-timeline">
            <li class v-for="(level, levelIndex) in levels" :key="levelIndex">
              <div class="timeline-icon bg-success">
                <span class="font-bold text-white"
                  >Level {{ parseInt(levelIndex) }}</span
                >
              </div>

              <div class="timeline-info">
                <!-- <p class="font-semibold">Approved</p> -->
                <ul class="list-inline mb-0 d-flex flex-wrap">
                  <li
                    class="list-inline-item mb-3"
                    v-for="(singleUser, userIndex) in level"
                    :key="'user' + userIndex"
                  >
                    <div
                      class="po-user-card px-3 rounded"
                      :class="
                        sidenavuser[0].user_id == singleUser.user_id &&
                        isRightSideNav &&
                        sidenavuser.length > 0
                          ? 'active'
                          : ''
                      "
                      @click="
                        checkViewEditPermission == false
                          ? showRightSideNav(singleUser, singleUser.user_id)
                          : ''
                      "
                    >
                      <!-- <i
                        @click="onRemoveLevel(level, singleUser)"
                        class="text-center material-icons level-close-icon"
                      >close</i>-->
                      <h6 class="font-semibold mb-0 text-center hoverKozoText">
                        {{ singleUser.user_name }}
                      </h6>
                    </div>
                  </li>
                  <li
                    v-if="checkViewEditPermission == false"
                    @click="openSelectUser(level)"
                    class="list-inline-item mb-0 ml-3"
                  >
                    <div class="add-user-btn px-3 rounded">
                      <h6 class="font-semibold mb-0 text-center">
                        <span class="pluse_1"></span>
                        <i class="text-center material-icons">add_circle</i>
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- <small class="text-grey activity-e-time">25 mins ago</small> -->
            </li>
            <li
              v-if="checkViewEditPermission == false"
              @click="onAddNewLevel()"
              class="mb-base pb-5"
            >
              <div class="add-level-icon">
                <span class="font-bold text-white">
                  <i class="text-center material-icons align-middle"
                    >add_circle</i
                  >
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </vx-card>

    <section>
      <div
        class="RightSideBarNav p-4"
        :class="isRightSideNav == true ? 'show' : ''"
        v-if="sidenavuser.length > 0"
      >
        <span class="close-icon" @click="closeRightSideNav">
          <img
            src="@/assets/images/icons/times-solid.svg"
            height="20"
            width="20"
            alt
          />
        </span>
        <div class="row border-bottom align-items-center pb-4 mb-5">
          <div class="col-4 pr-0">
            <div class="editprojectuser-img">
              <img
                :src="sidenavuser[0].user_pic + '?token=' + tempToken"
                class="img-fluid"
                alt
              />
            </div>
          </div>
          <div class="col-8">
            <h5 class="text-truncate">{{ sidenavuser[0].user_name }}</h5>
            <p class="small text-muted">{{ sidenavuser[0].role_name }}</p>
            <p class="small text-muted">{{ sidenavuser[0].department_name }}</p>
            <!-- <p class="small text-muted">{{ sidenavuser[0].notifyother }}</p> -->
          </div>
        </div>

        <div class="RightSideBarNav_body mb-4">
          <ul class="list-unstyled">
            <!-- {{showPermission}} -->
            <li
              class="mb-2"
              v-for="(item, index) in showPermission"
              :key="index"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'customCheck' + index"
                  v-model="item.value"
                />
                <label
                  class="custom-control-label"
                  :for="'customCheck' + index"
                  >{{ item.text }}</label
                >
              </div>
            </li>
            <!-- <li>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck2" />
                <label class="custom-control-label" for="customCheck2">Admin</label>
              </div>
            </li>-->
          </ul>

          <div class="col-10 pl-0">
            <div class="row mx-0">
              <div class="col pl-0 pr-2">
                <h6 class="mb-0">Normal Approver</h6>
              </div>
              <div class="col pl-0 text-center">
                <b-form-checkbox
                  value="1"
                  unchecked-value="0"
                  v-model="requiredApprovalToggle"
                  name="toggle-button"
                  switch
                ></b-form-checkbox>
              </div>
              <div class="col">
                <h6 class="mb-0">Required Approver</h6>
              </div>
            </div>
          </div>

          <div class="col-10 pl-0 mt-4">
            <div class="row">
              <div class="col-12 mb-3">
                <h6>Approval/Rejection Email (to other Member)</h6>
                <!-- v-validate="'required'" -->
                <vs-input
                  name="project name"
                  v-model="sidenavuser[0].notifyother"
                  class="w-full"
                  rules="alpha"
                />
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('project name')"
                    >{{ errors.first("project name") }}</span
                  >
                </p>
              </div>
            </div>
          </div>

          <!-- <div class="mt-10" @click="updateMapDetail">
            <div class="col-8 py-3 rounded remove_btn">Submit</div>
          </div>-->
        </div>

        <!-- <vs-button @click="popupActivo4=true" color="danger" type="border">Open fullscreen popup</vs-button> -->
        <vs-popup
          fullscreen
          title="User Rights Management Module"
          :active.sync="popupActivo4"
          class="user-rights-popup"
        >
          <div v-if="showPermissiondiv == true">
            <h3>
              <b>
                User : {{ sidenavuser[0].user_name
                }}<small>
                  ( {{ sidenavuser[0].role_name }}
                  {{ sidenavuser[0].department_name }} )
                </small>
              </b>
            </h3>
            <div
              class="pt-5"
              v-for="(itemx, index) in managementPermissionsArry"
              :key="index"
            >
              <div
                v-if="
                  checkUserType &&
                  checkUserType == '0' &&
                  itemx.module_id != PermissionVariables.Contract &&
                  itemx.module_id != PermissionVariables.Settings &&
                  itemx.module_id != PermissionVariables.DCR &&
                  itemx.module_id != PermissionVariables.Vendors &&
                  itemx.module_id != PermissionVariables.AdvanceRequest
                "
              >
                <h6>{{ itemx.module_name }}</h6>
                <ul>
                  <li
                    v-for="(itemy, indexChild) in managementPermissionsArry[
                      index
                    ].permissionArry"
                    :key="indexChild"
                  >
                    <div class="d-flex">
                      <div>
                        <!-- v-model="itemy.permissionStatus" -->
                        <b-form-checkbox
                          @change="onChangeSelectedPermission(itemy)"
                          :disabled="itemy.permission_id == '26' ? true : false"
                          v-model="itemy.permission_status"
                          value="1"
                          unchecked-value="0"
                          name="check-button"
                        ></b-form-checkbox>

                        <!-- <input type="checkbox" value="1" /> -->
                      </div>

                      <div class="pl-2">
                        {{
                          itemy.permission_id == "33"
                            ? itemy.permission_name +
                              " " +
                              projectDetail.project_name
                            : itemy.permission_name
                        }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                v-else-if="
                  checkUserType &&
                  checkUserType == '1' &&
                  (itemx.module_id == PermissionVariables.Contract ||
                    itemx.module_id == PermissionVariables.Settings ||
                    itemx.module_id == PermissionVariables.DCR ||
                    itemx.module_id == PermissionVariables.Vendors) &&
                  itemx.module_id != PermissionVariables.AdvanceRequest
                "
              >
                <h6>{{ itemx.module_name }}</h6>
                <ul>
                  <li
                    v-for="(itemy, indexChild) in managementPermissionsArry[
                      index
                    ].permissionArry"
                    :key="indexChild"
                  >
                    <div class="d-flex">
                      <div>
                        <!-- v-model="itemy.permissionStatus" -->
                        <b-form-checkbox
                          @change="onChangeSelectedPermission(itemy)"
                          :disabled="itemy.permission_id == '26' ? true : false"
                          v-model="itemy.permission_status"
                          value="1"
                          unchecked-value="0"
                          name="check-button"
                        ></b-form-checkbox>

                        <!-- <input type="checkbox" value="1" /> -->
                      </div>

                      <div class="pl-2">
                        {{
                          itemy.permission_id == "33"
                            ? itemy.permission_name +
                              " " +
                              projectDetail.project_name
                            : itemy.permission_name
                        }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                v-else-if="
                  checkUserType &&
                  checkUserType == '2' &&
                  itemx.module_id != PermissionVariables.AdvanceRequest
                "
              >
                <h6>{{ itemx.module_name }}</h6>
                <ul>
                  <li
                    v-for="(itemy, indexChild) in managementPermissionsArry[
                      index
                    ].permissionArry"
                    :key="indexChild"
                  >
                    <div class="d-flex">
                      <div>
                        <!-- v-model="itemy.permissionStatus" -->
                        <b-form-checkbox
                          @change="onChangeSelectedPermission(itemy)"
                          :disabled="itemy.permission_id == '26' ? true : false"
                          v-model="itemy.permission_status"
                          value="1"
                          unchecked-value="0"
                          name="check-button"
                        ></b-form-checkbox>

                        <!-- <input type="checkbox" value="1" /> -->
                      </div>

                      <div class="pl-2">
                        {{
                          itemy.permission_id == "33"
                            ? itemy.permission_name +
                              " " +
                              projectDetail.project_name
                            : itemy.permission_name
                        }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                v-else-if="
                  itemx.module_id == PermissionVariables.AdvanceRequest &&
                  checkAdvanceReq == '1'
                "
              >
                <h6>{{ itemx.module_name }}</h6>
                <ul>
                  <li
                    v-for="(itemy, indexChild) in managementPermissionsArry[
                      index
                    ].permissionArry"
                    :key="indexChild"
                  >
                    <div class="d-flex">
                      <div>
                        <!-- v-model="itemy.permissionStatus" -->
                        <b-form-checkbox
                          @change="onChangeSelectedPermission(itemy)"
                          :disabled="itemy.permission_id == '26' ? true : false"
                          v-model="itemy.permission_status"
                          value="1"
                          unchecked-value="0"
                          name="check-button"
                        ></b-form-checkbox>

                        <!-- <input type="checkbox" value="1" /> -->
                      </div>

                      <div class="pl-2">
                        {{
                          itemy.permission_id == "33"
                            ? itemy.permission_name +
                              " " +
                              projectDetail.project_name
                            : itemy.permission_name
                        }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="vx-row mt-4">
              <div class="mt-4 ml-4">
                <!--"  -->
                <vs-button
                  v-if="showButton == true"
                  type="filled"
                  @click.prevent="updateUserPermissions('1')"
                  >Update Permissions</vs-button
                >
              </div>
              <div class="vx-col ml-auto mt-4">
                <!--"  -->
                <vs-button
                  v-if="
                    userAddedPermission.length >
                    managementPermissionsArry.length
                  "
                  type="filled"
                  @click.prevent="resetUserPermissions"
                  >Reset Permissions</vs-button
                >
              </div>
            </div>
          </div>
          <!-- <div class="pt-5">
            <h6>Projects</h6>
          </div>

          <div class="pt-5">
            <h6>Users</h6>
            <ul>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Add</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Edit</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Delete</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">View</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Export</div>
                </div>
              </li>
            </ul>
          </div>

          <div class="pt-5">
            <h6>Vendors</h6>
            <ul>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Add</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Edit</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Archive</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Export</div>
                </div>
              </li>
            </ul>
          </div>

          <div class="pt-5">
            <h6>Expenses</h6>
            <ul>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Create expenses</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">View project related expenses</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">View organization related expense</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Expense status</div>
                </div>
              </li>
            </ul>
          </div>

          <div class="pt-5">
            <h6>Budget</h6>
            <ul>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Add</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Edit</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Delete</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">View</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Export</div>
                </div>
              </li>
            </ul>
          </div>

          <div class="pt-5">
            <h6>Inventory</h6>
            <ul>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Create inventory</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Inventory list</div>
                </div>
              </li>
            </ul>
          </div>

          <div class="pt-5">
            <h6>Daily Cost Report</h6>
          </div>

          <div class="pt-5">
            <h6>Contract</h6>
          </div>

          <div class="pt-5">
            <h6>Advance Request</h6>
            <ul>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">View & Allocate Request List</div>
                </div>
              </li>
            </ul>
            <ul>
              <h6>Cash allocated user list:</h6>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">View Advance History</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">View Expense</div>
                </div>
              </li>
            </ul>
          </div>

          <div class="pt-5">
            <h6>Settings:</h6>
            <ul>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">GST add & view</div>
                </div>
              </li>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">GST view</div>
                </div>
              </li>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Payment mode add & view</div>
                </div>
              </li>
              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Payment mode view</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Priority add & view</div>
                </div>
              </li>

              <li>
                <div class="d-flex">
                  <div>
                    <input type="checkbox" />
                  </div>
                  <div class="pl-2">Priority view</div>
                </div>
              </li>
            </ul>
          </div> -->
        </vs-popup>

        <div class="RightSideBarNav_footer">
          <div class="mb-3 px-3">
            <div
              v-if="loginUserRole == 'Admin'"
              class="w-100 py-3 rounded remove_btn mr-1"
              @click="openUserPopUp"
              color="danger"
              type="border"
            >
              User Permission
            </div>
          </div>

          <div class="d-flex align-items-center px-3">
            <!-- @click="removeMapDetail" -->
            <div
              class="w-50 py-3 rounded remove_btn mr-1"
              @click="updateMapDetail"
            >
              Submit
            </div>
            <div
              class="w-50 py-3 rounded remove_btn ml-1"
              @click="removeFromLevel"
            >
              Remove
            </div>

            <!-- <div class="w-50 py-3 rounded remove_btn ml-1" @click="closeRightSideNav">Close</div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import addLevel from "@/views/apps/projects/addLevel.vue";
import levelOverView from "@/views/apps/projects/levelOverView.vue";
import removeLevel from "@/views/apps/projects/removeLevel.vue";
import _ from "lodash";
import projectService from "@/services/projectService.js";
import userPermissionService from "@/services/userPermissionService.js";
import UserService from "@/services/UserService.js";
import CompanyService from "@/services/companyService.js";
import axios from "axios";
import { BASEURL } from "@/config/index";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import Dialogs from "../../components/vuesax/dialogs/Dialogs.vue";

export default {
  data() {
    return {
      toother: "",
      showButton: true,
      showPermissiondiv: false,
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      user_permission_id: null,
      userManage_userId: null,
      tempToken: window.localStorage.getItem("TempToken"),
      checkShowHideButton: false,
      popupActive: false,
      popupActive1: false,
      popupActive2: false,
      isActive: false,
      isSidebarActive: false,
      isRightSideNav: false,
      popupActivo4: false,
      users: [],
      form: {
        // moderator_array: [],
        // adminManager_array: [],
      },
      checkUserLevel: false,
      userDataAvailable: [],
      user_id: "",
      user_value: [],
      companies: [],
      userDataValue: [],
      adminManager_value: [],
      auditor_value: [],
      selectedUser: {},
      showPermission: [],
      RightSideBarNav: {},
      projectDetail: {
        project_name: null,
        project_icon: "",
        project_details: null,
        company_id: null,
        user_id: null,
        project_active: null,
        moderation: null,
        adminManager_user_id: null,
        project_budgetactive: 1,
        contract_budgetactive: 1,
        editUserType: 0,
      },
      sidenavuser: [
        {
          user_id: "",
        },
      ],
      project_budgetactive_copy: null,
      company_value: [],
      userDataAll: [],
      adminManegerUsers: [],
      AuditorList: [],
      analyticsData: "",
      selectedLevel: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      newlevels: {
        levelName: "",
        user_selected: [],
      },
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot",
      ],
      project_id: "",
      levels: [],
      showLeveldrop: [],
      level_add: "",
      admin_array_new: [],
      mod_array_new: [],
      requiredApprovalToggle: 0,
      managementPermissionsArry: [],
      userAddedPermission: [],
    };
  },
  beforeMount: function () {
    this.project_id = this.$route.params.projectID;
    this.toother = this.sidenavuser[0].notifyother;
    this.getProjectDetails();
  },
  beforeDestroy() {
    let _id = document.getElementById("popmodaluser");
    _id.setAttribute("style", "display: none;");
    let _id2 = document.getElementById("popmodallevel");
    _id2.setAttribute("style", "display: none;");
  },
  computed: {
    checkViewEditPermission() {
      if (window.localStorage.getItem("permissionPageAccess")) {
        if (
          this.permissionPageAccessArry.includes(
            this.PermissionVariables.viewEditSection
          ) &&
          this.permissionPageAccessArry.includes(
            this.PermissionVariables.ViewOrganizationProjects
          )
        ) {
          return true;
        } else if (
          this.permissionPageAccessArry.includes("39P" + this.project_id)
        ) {
          return true;
        } else {
          return false;
        }
        // return window.localStorage
        //   .getItem("permissionPageAccess")
        //   .split(",")
        //   .includes(this.PermissionVariables.viewEditSection)
        //   ? true
        //   : false;
      } else {
        return false;
      }
    },
    checkUserType() {
      return window.localStorage.getItem("UserType");
    },

    checkAdvanceReq() {
      return window.localStorage.getItem("isAdvanced");
    },
    UserRoleCheck() {
      return window.localStorage.getItem("UserRole");
    },
    contractForm() {
      if (
        this.projectDetail.editUserType == 1 ||
        this.projectDetail.editUserType == 2
      ) {
        return false;
      } else {
        return true;
      }
    },
    projectForm() {
      if (
        this.projectDetail.editUserType == 0 ||
        this.projectDetail.editUserType == 2
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkStatusButton() {
      if (this.projectDetail.isAdvanced > 0) {
        return false;
      } else if (this.projectDetail.isAdvanced == 0) {
        return false;
      }
    },
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return "";
      } else {
        return "Please enter a valid email address";
      }
    },
    openUserPopUp() {
      this.popupActivo4 = true;
      this.isRightSideNav = false;
      if (this.loginUserRole == "Admin") {
        this.userManagementFlowDiag(this.project_id, this.userManage_userId);
      }
    },

    onChangeSelectedPermission(itemy) {
      if (itemy.permission_id == "33") {
        let array = [];
        array = this.managementPermissionsArry.filter((x) => {
          return x.module_id == itemy.module_id;
        });
        if (array.length > 0) {
          let arrPer = [];
          arrPer = array[0].permissionArry.filter((y) => {
            return y.permission_id == 31;
          });
          if (arrPer.length > 0) {
            if (arrPer[0].permission_status == "1") {
              arrPer[0].permission_status = 0;
            }
          }
        }
      }
      if (itemy.permission_id == "31") {
        let array1 = [];
        array1 = this.managementPermissionsArry.filter((x) => {
          return x.module_id == itemy.module_id;
        });
        if (array1.length > 0) {
          let arrPer1 = [];
          arrPer1 = array1[0].permissionArry.filter((y) => {
            return y.permission_id == 33;
          });
          if (arrPer1.length > 0) {
            if (arrPer1[0].permission_status == "1") {
              arrPer1[0].permission_status = 0;
            }
          }
        }
      }
      if (itemy.permission_id == "36") {
        let array1 = [];
        array1 = this.managementPermissionsArry.filter((x) => {
          return x.module_id == itemy.module_id;
        });
        if (array1.length > 0) {
          let arrPer1 = [];
          arrPer1 = array1[0].permissionArry.filter((y) => {
            return y.permission_id == 39;
          });
          if (arrPer1.length > 0) {
            if (arrPer1[0].permission_status == "1") {
              arrPer1[0].permission_status = 0;
            }
          }
        }
      }
      if (itemy.permission_id == "39") {
        let array1 = [];
        array1 = this.managementPermissionsArry.filter((x) => {
          return x.module_id == itemy.module_id;
        });
        if (array1.length > 0) {
          let arrPer1 = [];
          arrPer1 = array1[0].permissionArry.filter((y) => {
            return y.permission_id == 36;
          });
          if (arrPer1.length > 0) {
            if (arrPer1[0].permission_status == "1") {
              arrPer1[0].permission_status = 0;
            }
          }
        }
      }
    },
    userManagementFlowDiag(project_id, userId) {
      this.$vs.loading();
      this.showPermissiondiv = false;
      this.user_permission_id = null;
      let payload = {
        project_id: project_id,
        userId: userId,
      };
      userPermissionService.getUserManagementFlow(payload).then((response) => {
        const { data } = response;

        if (data.status == true || data.status == "true") {
          this.managementPermissionsArry = data.data;
          this.userAddedPermission = data.dataUser;

          this.managementPermissionsArry.map((x, index) => {
            this.managementPermissionsArry[index]["user_permission_id"] = "";
            if (data.dataUser.length > 0) {
              if (x.module_id === data.dataUser[index].module_id) {
                this.managementPermissionsArry[index]["user_permission_id"] =
                  data.dataUser[index].user_permission_id;

                //  if (data.dataUser[index].permission_array) {
                let addedPermissio =
                  data.dataUser[index].permission_array.split(",");

                this.managementPermissionsArry[index].permissionArry.map(
                  (y, index2) => {
                    if (addedPermissio.includes(y.permission_id.toString())) {
                      y.permission_status = 1;
                    }
                  }
                );
                // }

                // this.managementPermissionsArry[
                //   index
                // ].permissionArry[0].permission_status = 1;
              }
            }
            //  data.dataUser[index].user_permission_id
            // console.log(x,"jitu22")
          });

          //   this.managementPermissionsArry = JSON.parse(
          //     data.dataUser[0].permission_array
          //   );
          //   this.user_permission_id = data.dataUser[0].user_permission_id;
          // } else {

          // }
        } else {
          this.$vs.loading.close();
          // this.popupActive1 = false;
          // this.$vs.notify({
          //   title: "Error",
          //   text: data.Message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "warning"
          // });
        }
        this.showPermissiondiv = true;

        this.updateUserPermissions("2");
      });
    },
    updateUserPermissions(id) {
      if (id == "1") {
        this.$vs.loading();
        this.showButton = false;
      }
      this.managementPermissionsArry.map((x, index) => {
        this.managementPermissionsArry[index]["permission_id_Arry"] = [];
        this.managementPermissionsArry[index]["permission_ids"] = "";
        this.managementPermissionsArry[index].permissionArry.map(
          (y, index1) => {
            if (y.permission_status == "1") {
              if (
                y.module_id == "1004" ||
                y.module_id == "1005" ||
                y.module_id == "1006" ||
                y.module_id == "1000" ||
                y.module_id == "1001"
              ) {
                this.managementPermissionsArry[index][
                  "permission_id_Arry"
                ].push(
                  y.permission_id +
                    "," +
                    y.permission_id +
                    "P" +
                    this.project_id
                );
              } else {
                this.managementPermissionsArry[index][
                  "permission_id_Arry"
                ].push(y.permission_id);
              }
            }
          }
        );
        this.managementPermissionsArry[index]["permission_ids"] =
          this.managementPermissionsArry[index][
            "permission_id_Arry"
          ].toString();
      });

      let payload = {
        organization_id: window.localStorage.getItem("OrganizationID"),
        permission_array: this.managementPermissionsArry,
        project_id: this.project_id,

        user_id: this.userManage_userId,
        // user_permission_id: this.user_permission_id
      };

      userPermissionService.addUserPermission(payload).then((response) => {
        const { data } = response;

        if ((data.status == true || data.status == "true") && id == "1") {
          this.$vs.notify({
            title: "Updated!",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.showButton = true;
          this.popupActivo4 = false;
          this.$vs.loading.close();
          //  this.managementPermissionsArry = data.data;
          // console.log(this.managementPermissionsArry);
        } else {
          this.$vs.loading.close();
          // this.popupActive1 = false;
          // this.$vs.notify({
          //   title: "Error",
          //   text: data.Message,
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "warning"
          // });
        }
      });

      // let ur = JSON.stringify(this.managementPermissionsArry);
      // console.log(JSON.stringify(this.managementPermissionsArry));
      // console.log(JSON.parse(ur));
    },
    resetUserPermissions() {
      this.$vs.loading();
      let payload = {
        project_id: this.project_id,
        userId: this.userManage_userId,
      };
      userPermissionService
        .resetUserPermissionsData(payload)
        .then((response) => {
          const { data } = response;

          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Permission Reset Done",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.popupActivo4 = false;
            this.$vs.loading.close();
            //  this.managementPermissionsArry = data.data;
            // console.log(this.managementPermissionsArry);
          } else {
            this.popupActivo4 = false;
            this.$vs.loading.close();

            // this.popupActive1 = false;
            // this.$vs.notify({
            //   title: "Error",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        });
    },
    updateMapDetail() {
      // console.log("updateMapDetail");
      if (
        this.sidenavuser[0].notifyother != "" &&
        this.sidenavuser[0].notifyother != "-"
      ) {
        if (this.validateEmail(this.sidenavuser[0].notifyother) != "") {
          this.$vs.notify({
            title: "Warning",
            text: "Please enter a valid email address",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
          return;
        }
      }

      // console.log("requiredApprovalValue", this.requiredApprovalToggle);
      this.$vs.loading();
      let payload = {
        ...this.sidenavuser[0],
        required_approver: parseInt(this.requiredApprovalToggle),
      };
      this.showPermission.map((x) => {
        payload[x.key] = x.value ? 1 : 0;
      });
      payload["notifyother"] = this.sidenavuser[0].notifyother;
      projectService
        .editProjectLevels({ ...payload }, payload.map_id)
        .then((response) => {
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.getProjectLevels();
            this.isRightSideNav = false;
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.user_value = [];
          } else {
            this.$vs.loading.close();
            this.popupActive1 = false;
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        });
    },

    onChangeRemove: function () {
      this.removeMapDetail();
      this.popupActive1 = false;
    },
    removeMapDetail() {
      this.$vs.loading();
      let payload = {
        ...this.sidenavuser[0],
        map_active: 3,
      };

      projectService
        .editProjectLevels({ ...payload }, payload.map_id)
        .then((response) => {
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.getProjectLevels();
            this.isRightSideNav = false;
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.user_value = [];
            this.isSidebarActive = false;
          } else {
            this.$vs.loading.close();
            this.popupActive1 = false;
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        });
    },
    showRightSideNav(singleUser, userId) {
      this.isSidebarActive = true;
      this.sidenavuser = [
        {
          user_id: "",
        },
      ];
      this.sidenavuser = [singleUser];
      this.showPermission = [
        {
          text: "Show Approved Expenses/PO",
          key: "is_approver",
          value: singleUser.is_approver == 0 ? false : true,
        },
        {
          text: "Can edit a user expense",
          key: "is_editor",
          value: singleUser.is_editor == 0 ? false : true,
        },
      ];
      console.log(localStorage.getItem("digiSign"));
      if (localStorage.getItem("digiSign") == 1) {
        this.showPermission.push({
          text: "Digital Signature Box",
          key: "is_sign_pad",
          value: singleUser.is_sign_pad == 0 ? false : true,
        });
      }

      if (window.localStorage.getItem("UserRole") == "Admin") {
        if (singleUser.role_name == "Admin Manager") {
          let obj = {
            text: "Admin Manager",
            key: "is_admin_manager_user",
            value: singleUser.is_admin_manager_user == 0 ? false : true,
          };
          this.showPermission.push(obj);
        } else if (singleUser.role_name == "Moderator") {
          let obj = {
            text: "Moderator",
            key: "is_moderator_user",
            value: singleUser.is_moderator_user == 0 ? false : true,
          };
          this.showPermission.push(obj);
        }
      }

      this.showPermission.push({
        text: "Only View Expenses",
        key: "is_view",
        value: singleUser.is_view == 0 ? false : true,
      });

      this.requiredApprovalToggle = singleUser.required_approver;
      this.isRightSideNav = true;
      this.userManage_userId = userId;
      if (this.loginUserRole == "Admin") {
        this.userManagementFlowDiag(this.project_id, this.userManage_userId);
      }
    },
    closeRightSideNav() {
      this.isRightSideNav = false;
      this.sidenavuser = [
        {
          user_id: "",
        },
      ];
      this.isSidebarActive = false;
    },

    checkAdvancedOption() {
      this.projectDetail.isAdvanced =
        this.projectDetail.isAdvanced == 0 ? 1 : 0;
      // this.projectDetail.project_budgetactive = this.projectDetail.isAdvanced;
      // if (this.projectDetail.isAdvanced == 0) {
      //   this.form.adminManager_array = [];
      //   this.form.moderator_array = [];
      // }
      // console.log(this.form.project_budgetactive);
    },
    uploadFile(event) {
      let that = this;
      let formData = new FormData();
      let organization_id = window.localStorage.getItem("OrganizationID");
      formData.append("file[]", event.target.files[0]);
      axios
        .post(
          BASEURL() + "/v2/multipleUploads?organization_id=" + organization_id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: this.access_token,
            },
          }
        )
        .then(function (res) {
          that.projectDetail.project_icon = res.data.data.image_path;
        })
        .catch(function (err) {
          this.$vs.notify({
            title: "Error!",
            text: error,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSelectModerator: function (selectedOption, id) {
      this.moderator_user_id = selectedOption.user_id;
      this.userDataAvailable = this.userDataAll.filter(function (obj) {
        return obj.user_id !== selectedOption.user_id;
      });
    },
    optionRemovedModerator: function (removedOption, id) {
      this.userDataAvailable.push(removedOption);
    },

    onSelectCompany: function ({ company_id }) {
      this.userDataAvailable = [];
      this.userDataAll = [];
      this.financeUsers = [];
      this.moderator_user_id = "";
      this.finance_user_id = "";
      this.levels = [
        {
          levelName: "1",
        },
      ];
      this.count = 2;
      if (company_id) {
        UserService.CompanyUsersList(company_id)
          .then((response) => {
            const { data } = response;
            if (data.Status == false) {
            } else {
              data.data.map((user, index) => {
                user.id = user.user_id;
                user.label = user.user_name;
                user.name = user.user_name;
                this.userDataAll.push(user);
              });
              this.userDataAvailable = this.userDataAll;
              this.financeUsers = this.userDataAll;
            }
          })
          .catch((error) => {
            console.log("error :", error);
          });
      }
    },
    submitForm: function () {
      this.projectDetail["moderation"] = 1;

      this.projectDetail["isAdvanced"] =
        Object.keys(this.levels).length > 0 ? 1 : 0;

      projectService
        .editProject({ ...this.projectDetail, project_id: this.project_id })
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Updated",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });

            this.checkShowHideButton = false;
            this.getProjectDetails();
          } else {
            this.projectDetail.project_budgetactive =
              this.project_budgetactive_copy;
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.getProjectDetails();
        })
        .catch((error) => {});
    },
    openSelectUser: function (level) {
      this.selectedLevel = level[0].user_level;
      this.popupActive = true;
      this.getUsersList(null);
    },
    onAddUser: function () {
      let payload = {
        project_id: this.project_id,
        user_level: this.selectedLevel,
        map_active: 1,
        user_id: this.user_id,
      };

      //  if (this.user_value.role_name == "Moderator") {
      //   payload["is_moderator_user"] = 1;
      //   payload["is_admin_manager_user"] = 0;
      // } else if (this.user_value.role_name == "Admin Manager") {
      //   payload["is_moderator_user"] = 0;
      //   payload["is_admin_manager_user"] = 1;
      // } else {
      if (this.selectedLevel == 1) {
        payload["is_sign_pad"] = 1;
        payload["is_editor"] = 1;
      } else {
        payload["is_sign_pad"] = 0;
        payload["is_editor"] = 0;
      }
      payload["is_moderator_user"] = 0;
      payload["is_admin_manager_user"] = 0;
      payload["is_approver"] = 1;

      //  }

      this.$vs.loading();
      projectService.addProjectLevels(payload).then((response) => {
        const { data } = response;
        if (data.Status == true || data.Status == "true") {
          this.popupActive = false;
          this.$vs.loading.close();
          this.user_id = "";
          this.selectedLevel = "";
          this.getProjectLevels();
          this.$vs.notify({
            title: "Updated!",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
          this.user_value = [];
        } else {
          this.$vs.loading.close();
          this.popupActive = false;

          this.$vs.notify({
            title: "Error",
            text: data.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
        this.getProjectDetails();
      });
    },
    onRemoveLevel: function (level, user) {
      this.selectedLevel = level[0].user_level;
      this.selectedUser = user;
    },
    removeFromLevel: function () {
      this.popupActive1 = true;
    },
    onChangeCheckbox: function (e) {
      let payload = {
        ...this.selectedUser,
        project_id: this.project_id,
        user_level: this.selectedLevel,
        map_active: 3,
        user_id: this.selectedUser.user_id,
      };
      let map_id = this.selectedUser.map_id;
      this.$vs.loading();
      projectService
        .editProjectLevels({ ...payload, map_id }, map_id)
        .then((response) => {
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.loading.close();
            this.popupActive1 = false;
            this.selectedLevel = "";
            this.selectedUser = {};
            this.getProjectLevels();
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.user_value = [];
          } else {
            this.$vs.loading.close();
            this.popupActive1 = false;
            this.$vs.notify({
              title: "Error",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        });
    },
    privStatus: function () {
      this.popupActive1 = false;
      this.selectedLevel = "";
      this.selectedUser = {};
    },
    onAddNewLevel: function () {
      this.popupActive2 = true;
      this.getUsersList(null);
    },
    optionSelected: function (selectedOption, id) {
      this.newlevels.user_selected.push(selectedOption);
      this.userDataAvailable = this.userDataAvailable.filter(function (obj) {
        return obj.user_id !== selectedOption.user_id;
      });
    },
    optionRemoved: function (removedOption, id) {
      this.userDataAvailable.push(removedOption);
    },
    AddLevel: function () {
      this.newlevels.user_selected.map((user, index) => {
        let payload = {
          project_id: this.project_id,
          user_level: this.level_add.levelName,
          map_active: 1,
          user_id: user.user_id,
        };
        //  if (user.role_name == "Moderator") {
        //   payload["is_moderator_user"] = 1;
        //   payload["is_admin_manager_user"] = 0;
        // } else if (user.role_name == "Admin Manager") {
        //   payload["is_moderator_user"] = 0;
        //   payload["is_admin_manager_user"] = 1;
        // } else {
        payload["is_moderator_user"] = 0;
        payload["is_admin_manager_user"] = 0;
        payload["is_approver"] = 1;
        payload["is_editor"] = 0;
        payload["is_sign_pad"] = 0;
        // }
        this.submitLevel(payload);
      });
    },
    submitLevel: function (payload) {
      this.$vs.loading();
      let count = 1;
      projectService
        .addProjectLevels(payload)
        .then((response) => {
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.loading.close();
            if (count == 1) {
              this.popupActive2 = false;
              this.newlevels = {
                levelName: "",
                user_selected: [],
              };
              this.getProjectLevels();
              count++;
              this.$vs.notify({
                title: "Updated!",
                text: data.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
            }
            this.userDataValue = [];
          } else {
            this.popupActive2 = false;
            this.$vs.loading.close();
            count++;
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.getProjectDetails();
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getUsersList: function (userIdID = getUsersListnull) {
      this.users = [];
      this.userDataAvailable = [];
      let filter = {
        // limit: this.paginationPageSize || 0
        project_id: this.project_id,
      };
      UserService.remainingUsers(this.project_id)
        .then((response) => {
          const { data } = response;
          console.log(
            "responseresponseresponseresponseresponseresponseresponse :",
            data.data,
            userIdID
          );
          if (data.err) {
          } else {
            this.users = userIdID
              ? data.data.filter((x) => {
                  return String(x.user_id) !== String(userIdID);
                })
              : data.data;

            // console.log("usersusersusersusers :",  this.users);

            // if (this.users.length == 0) {
            //   this.users.push({
            //     user_id: "",
            //     user_name: "List is Empty"
            //   });
            // }
            data.data.map((user, index) => {
              if (String(user.user_id) !== String(userIdID)) {
                user.id = user.user_id;
                user.label = user.user_name;
                user.name = user.user_name;
                this.userDataAvailable.push(user);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    myFilter: function () {
      this.isActive = !this.isActive;
    },
    getProjectLevels: function () {
      this.RightSideBarNav = false;
      projectService
        .getProjectLevels(this.project_id)
        .then((response) => {
          const { data } = response;

          if (data.Status == true || data.Status == "true") {
            data.data = _.groupBy(data.data, "user_level");

            delete data.data[0];
            let len = Object.keys(data.data).length;
            //  console.log(len, "fsfsdf");
            if (len > 0) {
              this.levels = data.data;
              let levelLength = Object.keys(this.levels)[
                Object.keys(this.levels).length - 1
              ];
              this.newlevels = {
                levelName: parseInt(levelLength) + 1,
                user_selected: [],
              };

              let obj = {
                levelName: parseInt(levelLength) + 1,
                levelShowName: "Level " + (parseInt(levelLength) + 1),
              };

              this.showLeveldrop = [];

              this.level_add = obj;
              this.showLeveldrop.push(obj);
              let array = [];
              Object.keys(this.levels).map((x) => {
                array.push(x);
              });

              for (let i = 1; i <= parseInt(levelLength); i++) {
                let findIndex = array.indexOf(String(i));
                if (findIndex < 0) {
                  let obj1 = {
                    levelName: i,
                    levelShowName: "Level " + i,
                  };
                  this.showLeveldrop.push(obj1);
                }
              }
            } else {
              this.newlevels = {
                levelName: 1,
                user_selected: [],
              };

              let obj = {
                levelName: 1,
                levelShowName: "Level 1",
              };

              this.showLeveldrop = [];

              this.level_add = obj;
              this.showLeveldrop.push(obj);
            }

            let mod_array = [];
            let admin_array = [];
            Object.keys(data.data).map((x) => {
              data.data[x].map((y) => {
                if (y.is_moderator_user == 1) {
                  mod_array.push(y);
                }
                if (y.is_admin_manager_user == 1) {
                  admin_array.push(y);
                }
              });
            });
            this.admin_array_new = admin_array;
            this.mod_array_new = mod_array;
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getProjectDetails: function () {
      projectService
        .getProject(this.project_id)
        .then((response) => {
          const { data } = response;
          this.projectDetail = {
            ...this.projectDetail,
            ...data.data,
          };
          // console.log(this.projectDetail, "fsdfdsfds");
          this.projectDetail["user_id"] = data.data.moderator_user_id;
          this.project_budgetactive_copy = data.data.project_budgetactive;
          this.projectDetail.isAdvanced = 1;
          this.getCompanyList(data.data.company_id);
          // this.getModeratorList(
          //   data.data.company_id,
          //   data.data.moderator_user_id
          // );
          if (this.projectDetail.isAdvanced != 1) {
            if (this.checkUserType == 1) {
              this.checkShowHideButton = false;
            } else {
              this.checkShowHideButton = true;
            }
          } else {
            this.getProjectLevels();
            this.checkUserLevel = true;
            this.getUsersList(null);
          }
          // this.getAdminManagerList(
          //   data.data.company_id,
          //   data.data.adminManager_user_id
          // );
          // this.form.adminManager_array = data.data.adminManager_array;
          // this.form.moderator_array = data.data.moderator_array;

          // console.log(this.UserRoleCheck,"fdsfdsfdsfdsfdsf");

          if (
            this.UserRoleCheck.includes("User") ||
            this.UserRoleCheck.includes("Moderator") ||
            this.checkUserType == 1
          ) {
            this.projectDetail.editUserType = 1;
          }

          //

          //
        })
        .catch((error) => {});
    },
    getAuditorList: function (company_id, moderator_user_id) {
      UserService.CompanyUsersList(company_id)
        .then((response) => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            data.data.map((user, index) => {
              if (user.user_id == moderator_user_id) {
                this.auditor_value = user;
              }
            });
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getAdminManagerList: function (company_id, adminManager_user_id) {
      projectService
        .getAdminManagerUsers(company_id)
        .then((response) => {
          const { data } = response;
          this.adminManegerUsers = data.data;
          if (data.Status == false) {
          } else {
            data.data.map((user, index) => {
              if (user.user_id == adminManager_user_id) {
                this.projectDetail["user_id"] = adminManager_user_id;
                this.adminManager_value = user;
              }
            });
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getModeratorList: function (company_id, moderator_user_id) {
      let id = company_id;

      projectService
        .getModeratorUsers(company_id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.AuditorList = data.data;
            data.data.map((user, index) => {
              if (user.user_id == moderator_user_id) {
                this.projectDetail["user_id"] = moderator_user_id;
                this.auditor_value = user;
              }
            });
          } else {
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },

    getCompanyList: function (company_id) {
      CompanyService.getAllCompanies()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.companies = data.data;
            data.data.map((x) => {
              if (company_id == x.company_id) {
                this.company_value = x;
              }
            });
          } else {
          }
        })
        .catch((error) => {});
    },
  },
  components: {
    addLevel,
    removeLevel,
    Dialogs,
  },
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .editprojectuser-img img {
    max-height: 150px;
    max-width: 110px;
    object-fit: cover;
    object-position: top;
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
// .user-rights-popup div {
//   padding-top: 20px;
// }

.user-rights-popup h6 {
  font-weight: bold;
  font-size: 18px;
}
.user-rights-popup ul {
  display: flex;
}

.user-rights-popup ul li {
  margin-right: 20px;
}
</style>
