import Api from "./Api.js";
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  getUserManagementFlow(payload) {
    let project_id = payload.project_id;
    let user_id = payload.userId;
    return Api().get(
      `/v2/userManagement/userMangeFlow/View?organization_id=${organization_id()}&project_id=${project_id}&user_id=${user_id}`
    );
  },
  addUserPermission(payload) {
    return Api().post(
      `/v2/userManagement/addUserPermission/Add?organization_id=${organization_id()}`,
      payload
    );
  },

resetUserPermissionsData(payload){
 let project_id = payload.project_id;
    let user_id = payload.userId;
    return Api().delete(
      `/v2/userManagement/resetUser/Delete?organization_id=${organization_id()}&project_id=${project_id}&user_id=${user_id}`
    );
}
};
