<template>
  <div id="dashboard-analytics">
    <div class="vx-row mx-0">
      <div class="vx-col w-full">
        <router-link to="/apps/projects">
          <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>

      <vs-tabs>
        <vs-tab label="Edit Project" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <!-- LEVEL OVER VIEW -->
            <div>
              <level-over-view></level-over-view>
            </div>
          </div>
        </vs-tab>
        <!-- <vs-tab label="Add on level" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <div>
              <add-level></add-level>
            </div>
          </div>
        </vs-tab>-->
        <!-- <vs-tab label="Remove on level" icon-pack="feather" icon="icon-box">
          <div>
            <remove-level></remove-level>
          </div>
        </vs-tab>-->
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import addLevel from "@/views/apps/projects/addLevel.vue";
import levelOverView from "@/views/apps/projects/levelOverView.vue";
import removeLevel from "@/views/apps/projects/removeLevel.vue";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: "",
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  },
  components: {
    addLevel,
    removeLevel,
    levelOverView
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: #fff !important;
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul {
  background-color: #10163a !important;
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.5);
}

.theme-dark .con-vs-tabs .theme-dark .con-ul-tabs,
.theme-dark .vs-tabs--ul span {
  color: #c2c6dc;
  font-weight: bold;
}
</style>
